<template>
  <div class="flex flex-wrap min-h-screen">
    <div class="w-1/2 bg-black"></div>
    <div class="w-1/2 flex items-center">
      <div class="wrapper mx-16 w-full">
        <img :src="require('../assets/icons/raditubelogoNoAnimation.svg')" alt=""> 
        <h2 class="text-2xl font-grey-darker-100 mt-40 leading-none"> Forgot your password </h2>
        <p class="font-grey-darker-200 text-base mt-10">
          Enter your email address and we will send you instructions to reset your password.
        </p>
        <form class="mt-6" @submit.prevent="resetPassword">
          <div class="w-full mt-4">
            <label for="email" class="w-full block text-base font-grey-darker-200 mb-2">Email address</label>
            <input 
              v-model="email"
              type="email" 
              id="email" 
              name="email"  
              class="h-8 base-border-grey-200 rounded border pl-4 outline-none base-font-gray-100 text-base"
              placeholder="email@email.com"
              required
            >
          </div>
          <div class="flex flex-wrap items-center mt-6">
            <button class="flex items-center justify-center font-sans text-base base-bg rounded-3xl w-28 h-8 mr-6" type="submit"> 
              Continue 
            </button>
            <router-link to="/" class="text-base font-bold linkColor"> or go back to RadiTube </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { warningToastConfig } from '@/helpers/constants'

  export default {
    name: 'Forgot password',
    data(){
      return{
        email: ''
      }
    },
    methods: {
      resetPassword() {
        this.$webAuth.changePassword({
          connection: 'Username-Password-Authentication',
          email: this.email
        }, (err, resp) => {
          if(err){
            this.$toasted.show(err.message, warningToastConfig)
            // console.log(err.message);
          }else{
            this.$toasted.show(resp, warningToastConfig)
            // console.log(resp);
          }
        });
      }
    },
  }
</script>

<style lang="scss" scoped>
.title_with_line{
  width: 100%;
  height: 1px;
  background-color: #BEBEBE;
}
.labelGrey{
  &:before{
    left: 0;
  }
}
</style>